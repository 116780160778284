@import './../../../assets/styles/helpers';
@import './../../../assets/fonts/fonts';

.footer {
  background-color: $black;
}

.footer_context_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  position: relative;
  padding: 4.375rem 5.7rem 6.375rem;
  background-color: $black;
  color: $white;
  @include laptop-min {
    padding: 2.625rem 1.563rem 1.563rem;
  }
  @include mobile {
    flex-direction: column;
    justify-content: flex-start;
  }

  .copyright {
    position: absolute;
    bottom: 1.688rem;
    left: 5.9rem;
    @include font(400, start, 'Lato', 0.625rem, 0.75rem);

    a {
      cursor: pointer !important;
      text-decoration: none !important;
    }

    @include laptop-min {
      bottom: 10px;
      left: 1.563rem;
    }
    @include mobile {
      position: relative;
      left: 0;
      bottom: 0;
    }
  }

}





