@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-Regular.woff2") format('woff2'),
  url('Montserrat-Regular.woff') format('woff'),
  url('Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ArticulatCF';
  src: url('ArticulatCF-Regular.otf') format('opentype')
}

@font-face {
  font-family: 'Lato';
  src: url('Lato-Regular.ttf') format('truetype')
}

