@import './../../../../assets/styles/helpers';
@import './../../../../assets/fonts/fonts';

.sitemap_block {
  width: 100%;
  max-width: 253px;
  margin: 0 10px;
  flex-shrink: 0;
  @include mobile {
    max-width: 100%;
    margin: 0 0 2.5rem 0;
  }
}

.block_with_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mobile {
    justify-content: flex-start;
    & > div:first-child {
      margin-right: 1.25rem;
    }
  }

  .title {
    @include font(600, center, 'ArticulatCF', 1rem, 1.125rem);
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: $white;
  margin: 1.188rem 0 1.5rem;
  @include mobile-min {
    margin: .5rem 0;
  }
}

.vertical_separator {
  margin: 0 15px;
}

.block_with_links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    margin-bottom: 1.25rem;
    @include mobile-min {
      margin-bottom: .5rem;
    }
  }
}