@import './../../../../../assets/styles/helpers';
@import './../../../../../assets/fonts/fonts';

.sitemap_link {
  @include font(500, start, 'Montserrat', 0.75rem, 1rem);
  color: $white;

  & > a:hover {
    color: $yellow_main !important;
    font-weight: 700;
  }
}

.link_separator {
}

.active_link {
  color: $yellow_main !important;
  font-weight: 700;
  text-decoration: underline;
}

