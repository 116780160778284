@import './../../../../assets/styles/helpers';
@import './../../../../assets/fonts/fonts';

.contact_block {
  width: 100%;
  max-width: 220px;
  flex-shrink: 1;
  @include mobile {
    max-width: 100%;
    margin-bottom: 2.5rem;;
  }
}

.block_with_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mobile {
    justify-content: flex-start;
    & > div:first-child {
      margin-right: 1.25rem;
    }
  }

  .title {
    @include font(600, center, 'ArticulatCF', 1rem, 1.125rem);
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: $white;
  margin: 1.188rem 0 1.5rem;
  @include mobile-min {
    margin: .5rem 0;
  }
}

.address {
  cursor: default;
  @include font(500, start, 'Montserrat', 0.75rem, 1rem);
}

.email {
  display: flex;
  align-items: center;
  margin: 1rem 0 0.75rem;
  @include font(700, start, 'Montserrat', 0.75rem, 1rem);

  &:hover {
    & > div:first-child {
      background-image: url("./../../../../assets/images/footer/mail.svg");
    }

    & > a {
      color: $green-main !important;
    }
  }

  & > div:first-child {
    margin-right: 0.813rem;
    width: 1.2rem;
    height: 0.85rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./../../../../assets/images/footer/mail.png");
  }

  & a {
    display: block;
    text-decoration: none;
    color: $black;
  }

}

.phone {
  display: flex;
  align-items: center;
  @include font(700, start, 'Montserrat', 0.75rem, 1rem);

  &:hover {
    & > div:first-child {
      background-image: url("./../../../../assets/images/footer/phone.svg");
    }

    & > a {
      color: $green-main !important;
    }
  }

  & > div:first-child {
    margin-right: 0.438rem;
    width: 1.1rem;
    height: 1.1rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./../../../../assets/images/footer/phone.png");
  }

  & a {
    display: block;
    text-decoration: none;
  }

}