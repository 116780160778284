@import './assets/styles/_reset.scss';
@import './assets/styles/_helpers.scss';
@import './assets/fonts/fonts';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: $white;
  -webkit-overflow-scrolling: touch;
}

:root {
  font-family: 'Montserrat';
  font-size: 16px;
}

::-webkit-scrollbar {
  width: 0;
}

.loading {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include font(500, center, 'Montserrat', 1.875rem, 1.875rem);
}

//for h1
.heading_1 {
  @include font(600, center, 'ArticulatCF', 2.25rem, 3rem);
}

//for h1 mobile
.heading_1_mini {
  @include font(600, center, 'ArticulatCF', 1.5rem, 1.813rem);
}

//for h2
.heading_2 {
  @include font(600, center, 'ArticulatCF', 1.5rem, 1.813rem);
}

//for h2 mobile
.heading_2_mini {
  @include font(600, center, 'ArticulatCF', 1.125rem, 1.25rem);
}

.heading_3 {
  @include font(600, center, 'ArticulatCF', 1.125rem, 1.25rem);
}

.heading_3_mini {
  @include font(600, center, 'ArticulatCF', 1rem, 1.125rem);
}

.heading_4 {
  @include font(600, center, 'ArticulatCF', 1rem, 1.25rem);
}

a {
  color: $white !important;
  text-decoration: underline;
}

/////for testimonials slider
#slider-mini > .slick-slider {
  width: 100%;
}

#slider-mini > .slick-slider .slick-slide {
  padding: 1px 7px;
}

#slider-mini > .slick-slider > .slick-list > .slick-track {
  margin: 1px -5px;
}

////for How it works slider
#colored-slider-block .use-spring-carousel-item {
  min-width: 380px;
}

#colored-slider-block .use-spring-carousel-track-wrapper {
  display: flex;
  align-items: center;
}





