@import './../../../../assets/styles/helpers';
@import './../../../../assets/fonts/fonts';

.menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 12;
  background-color: $white;
}

.block_with_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  margin-bottom: 70px;
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;

  & button {
    margin: 0 auto;
  }

  & > a {
    position: relative;
    z-index: 12;
    padding: 20px;
    width: 100%;
    color: $black !important;
    text-decoration: none;
    display: block;
    @include font(800, center, 'Montserrat', 1.125rem, 1.25rem);
  }

  & > a:hover {
    background-color: #e3e2e2;
    color: $yellow-main !important;
  }

  .active_link {
    color: $yellow_main !important;
  }
}