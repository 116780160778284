@import './../../../assets/styles/helpers';

.error_page {
  width: 100%;
  min-height: 100%;
  display: flex;
  padding: 100px 10px 0;
  margin: auto;
  flex-direction: column;
  align-items: center;

  &_please {
    @include font(400, center, 'ArticulatCF', 1.2rem, 1.3rem);
    margin-bottom: 20px;
  }

  &_image {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin: 50px 0;
    @include mobile {
      width: 100px;
      height: 100px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &_sorry {
    @include font(600, center, 'ArticulatCF', 1.125rem, 1.25rem);
    margin-bottom: 40px;
    @include mobile {
      @include font(600, center, 'ArticulatCF', 1rem, 1.125rem);
    }
  }

  &_message {
    @include font(500, center, 'ArticulatCF', 0.875rem, 1.2rem);
    margin-bottom: 20px;
  }

  a {
    text-decoration: none;
  }
}