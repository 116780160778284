@import './../../../../assets/styles/helpers';
@import './../../../../assets/fonts/fonts';

.social_media_block {
  width: 100%;
  max-width: 310px;
  @include mobile {
    margin-bottom: 2.5rem;
  }

  &_part_with_title {
    display: flex;
    align-items: center;
    margin-bottom: 2.188rem;
    @include mobile {
      margin-bottom: 1.875rem;
    }
    @include mobile-min {
      margin-bottom: 1rem;
    }

    .smile_img {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background-color: $yellow_main;
      margin-right: 1.25rem;
      flex-shrink: 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      & > div {
        margin-top: 20px;
        @include mobile {
          margin-top: 10px;
        }
      }

      @include mobile {
        width: 2.6rem;
        height: 2.6rem;
        margin-right: 1.438rem;
      }
    }

    .title {
      @include font(600, center, 'ArticulatCF', 1.5rem, 1.813rem);
      text-decoration: underline;
    }
  }

  &_part_with_icons {
    display: flex;
    align-items: center;

    a {
      display: block;
      overflow: hidden;
      text-decoration: none;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    & > a:nth-child(2) {
      margin: 0 1.563rem;
    }
  }
}

.facebook_icon,
.twitter_icon,
.linkedin_icon {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.facebook_icon {
  background-image: url("./../../../../assets/images/footer/facebook-white.png");

  &:hover {
    background-image: url("./../../../../assets/images/footer/faceebook_green.png");
  }
}

.twitter_icon {
  background-image: url("./../../../../assets/images/footer/twitter.svg");

  &:hover {
    background-image: url("./../../../../assets/images/footer/twitter_yellow.svg");
  }
}

.linkedin_icon {
  background-image: url("./../../../../assets/images/footer/linkedin.png");

  &:hover {
    background-image: url("./../../../../assets/images/footer/linkedin_red.svg");
  }
}

