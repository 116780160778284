$yellow_main: #FFC20E;
$green_main: #6EBC20;
$red_main: #DB0029;
$pink_main: #ff1f5b;
$blue_main: #095AA5;
$grey_main: #ADADAD;
$black: #000;
$grey: #333333;
$white: #FFF;
$body: #4f4a4a;
$policy_link: #0583f5;

$form_text: rgba(0, 0, 0, 0.51);

//breakpoint
$desctop: 1440px;
$laptop: 1200px;
$laptop-min: 1024px;
$mobile: 768px;
$mobile-min: 576px;
$mobile-extra-min: 360px;

//fonts
@mixin font ($weight, $text-align, $family, $font-size, $line-height){
  font-family: $family;
  font-weight: $weight;
  font-size: $font-size;
  line-height: $line-height;
  text-align: $text-align;
}


@mixin desc {
  @media screen and (min-width: $desctop) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: $laptop) {
    @content;
  }
}

@mixin laptop-min {
  @media screen and (max-width: $laptop-min) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $mobile) {
    @content;
  }
}

@mixin mobile-min {
  @media screen and (max-width: $mobile-min) {
    @content;
  }
}

@mixin mobile-extra-min {
  @media screen and (max-width: $mobile-extra-min) {
    @content;
  }
}