@import './../../../assets/styles/helpers';
@import './../../../assets/fonts/fonts';

.header {
  background-color: $white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.header_context_wrapper {
  padding: 0 5.7rem 0 6rem;
  @include mobile {
    padding: 0 1.563rem 0 1.563rem;
  }
  @include mobile-min {
    padding-top: 1.063rem;
  }

  .context_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  hr {
    background-color: $black;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    @include mobile-min {
      margin-top: 1.25rem !important;
    }
  }
}

.navigation {
  min-height: 4.688rem;
  display: flex;
  align-items: stretch;

  & > a {
    text-decoration: none;
  }

  & > a:last-child {
    margin-left: 10px;
  }


  .active_link {
    color: $yellow_main !important;
  }
}

.link_ease_in_out a {
  height: 100%;
  padding-top: 1.75rem;
  display: inline-block;
  color: $black !important;
  text-decoration: none;
  margin-right: 3rem;
  @include font(500, start, 'Montserrat', 0.75rem, 0.875rem);
  @include laptop-min {
    margin-right: 2rem;
  }
  @include mobile {
    margin-right: 1.5rem;
  }

}

.link_ease_in_out a:after {
  background-color: $yellow_main; /* Цвет линии при наведении на нее курсора мыши */
  display: block;
  margin-top: 2px;
  content: "";
  height: 2px; /* Высота линии */
  width: 0%;
  -webkit-transition: width .3s ease-in-out;
  -moz--transition: width .3s ease-in-out;
  transition: width .3s ease-in-out;
}

.link_ease_in_out a:hover:after {
  color: black;
  width: 100%;
}

.link_ease_in_out a:hover {
  font-weight: 700;
  color: black !important;
}

.trial_link {
  padding-top: 1.188rem;
}

.burger {
  width: 1.563rem;
  height: 1rem;
  overflow: hidden;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}