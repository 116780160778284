.app_wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main_content_wrapper {
  flex-grow: 1;
  background-color: white;
}

.center {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
}