@import './../../../assets/styles/helpers';
@import './../../../assets/fonts/fonts';

.custom_button {
  border-radius: 4px;
  border: 1px solid $black;
  cursor: pointer;
  display: block;
}

.trial_button {
  background-color: $black;
  color: $yellow_main;
  padding: 0.563rem 0.938rem;
  @include font(700, center, 'Montserrat', 0.75rem, 0.75rem);

  &:hover {
    background-color: $yellow_main;
    color: $black;
  }
}

.main_button {
  background-color: $yellow_main;
  color: $black;
  padding: 0.75rem 1.25rem;
  @include font(700, center, 'Montserrat', 0.875rem, 1.125rem);
  @include mobile {
    padding: 0.6rem 1rem;
  }

  &:hover {
    background-color: $black;
    color: $yellow_main;
  }

}